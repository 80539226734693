import { ShipmentDetailLineItemTabId } from '../models/eshipment-detail-line-item-tab-id';
import { Payload } from './../../../../models/payload';
import { createAction, props } from '@ngrx/store';
import { ShipmentDetail } from '../../common/models/shipment-detail';
import { ShipmentDetailTabs } from '../models/shipment-detail-tabs';
import { LineItem } from '../../common/models/line-item';
import { GridConfig } from '../../../../models/grid-config';
import { ItemValidation } from '../../common/models/item-validation';
import { EditShipmentEditForm } from '../models/edit-shipment-edit-form';
import { GhostLineItem } from '../../common/models/ghost-line-item';
import { EditShipmentStatusForm } from '../models/edit-shipment-status-form';
import { ItemDataObject } from '../../../../models/itemData.model';
import { IShipmentsCloneDto } from '../../common/models/ishipments-clone-dto';
import { IShipmentsCloneByCustomerDto } from '../../common/models/ishipments-clone-by-customer';
import { CloneShipmentByCustomerResponse } from '../../common/models/clone-shipment-by-customer-response.interface';
import { CloneShipmentByCustomerGuid } from '../../common/models/clone-shipment-by-customer-guid.interface';
import { DeleteShipmentResponseObj } from '../../common/models/delete-shipments-dto.interface';
import { IShipmentsCloneByChainDto } from '../../common/models/ishipments-clone-by-chain';

export enum ShipmentDetailActions {
  LoadShipmentDetail = '[Shipment Detail] Load Shipment Detail',
  LoadShipmentDetailSuccess = '[Shipment Detail] Load Shipment Detail Success',
  LoadShipmentDetailError = '[Shipment Detail] Load Shipment Detail Error',

  AttemptCancelEdit = '[Shipment Detail] Attempt Cancel Edit',
  ConfirmCancelEdit = '[Shipment Detail] Confirm Cancel Edit',

  ShowUnsavedChangesModal = '[Shipment Detail] Show Unsaved Changes Modal',
  HideUnsavedChangesModal = '[Shipment Detail] Hide Unsaved Changes Modal',

  SaveChanges = '[Shipment Detail] Save Changes',
  SaveChangesSuccess = '[Shipment Detail] Save Changes Success',
  SaveChangesError = '[Shipment Detail] Save Changes Error',

  ClearSaveSuccess = '[Shipment Detail] Clear Save Success Banner',
  ClearSaveError = '[Shipment Detail] Clear Save Error Banner',

  ToggleNavigationPanel = '[Shipment Detail] Toggle Navigation Panel',
  SetCurrentTab = '[Shipment Detail] Set Current Tab',

  LoadLineItems = '[Shipment Detail] Load Line Items',
  LoadLineItemsSuccess = '[Shipment Detail] Load Line items Success',
  LoadLineItemsError = '[Shipment Detail] Load Line Items Error',
  LineItemSortChanged = '[Shipment Detail] Line item sort changed',
  PaginationStateChanged = '[Shipment Detail] Pagination State Change',
  LineItemListSearchChange = '[Shipment Detail] Line Item List Search State Change',
  ClearLineItemListSearch = '[Shipment Detail] Clear Line Item List Search for All Tabs',
  LoadQuantityOnHandSuccess = '[Shipment Detail] Load Quantity on Hand Success',

  ChangeTabLineItem = '[Shipment Detail] Change Tab Line Item',
  LoadLineItemAvailableCount = '[Shipment Detail] Load Line Item Available Count' ,
  LoadLineItemAvailableCountSuccess = '[Shipment Detail] Load Line Item Available Count Success',
  LoadLineItemAvailableCountError = '[Shipment Detail] Load Line Item Available Count Error',

  ToggleLineItemSelect = '[Shipment Detail] Toggle LineItem selected',
  ToggleLineItemSelectAll = '[Shipment Detail] Toggle LineItem selected all',

  SyncShipmentDetail = '[Shipment Detail] Sync Shipment Detail',
  SyncShipmentDetailSuccess = '[Shipment Detail] Sync Shipment Detail Success',
  SyncShipmentDetailError = '[Shipment Detail] Sync Shipment Detail Error',

  ValidateItemUpc = '[Shipment Detail] Validate Item Upc',
  ValidateItemUpcSuccess = '[Shipment Detail] Validate Item Upc Success',
  ValidateItemUpcError = '[Shipment Detail] Validate Item Upc Error',

  ValidateDuplicateItem = '[Shipment Detail] Validate Duplicate Item',
  ValidateDuplicateItemError = '[Shipment Detail] Validate Duplicate Item Error',

  ValidateItemDept = '[Shipment Detail] Validate Item Department',
  ValidateItemDeptSuccess = '[Shipment Detail] Validate Item Department Success',
  ValidateItemDeptError = '[Shipment Detail] Validate Item Department Error',

  ValidateAddLineItem = '[Shipment Detail] Quick Add Validate Line Item',
  ValidateAddLineItemSuccess = '[Shipment Detail] Quick Add Validate Line Item Success',
  ValidateAddLineItemError = '[Shipment Detail] Quick Add Validate Line Item Error',

  validateUpdateLineItem = '[Shipment Detail] Validate Updates for Line Item',
  validateUpdateLineItemSuccess = '[Shipment Detail] Update Validation Success No Line Item Errors',
  validateUpdateLineItemSuccessWithErrors = '[Shipment Detail] Update Validation Success With Line Item Errors',
  validateUpdateLineItemError = '[Shipment Detail] Validation for Line Item Update Failed',

  UndoDeleteLineItem = '[Shipment Detail] Undo Delete Line Item',
  UndoDeleteLineItemSuccess = '[Shipment Detail] Undo Delete Line Item Success',
  UndoDeleteLineItemError = '[Shipment Detail] Undo Delete Line Item Error',

  validateDeleteLineItem = '[Shipment Detail] Delete Validate Line Item',
  validateDeleteLineItemSuccess = '[Shipment Detail] Delete Validate Line Item Success',
  validateDeleteLineItemError = '[Shipment Detail] Delete Validate Line Item Error',

  validateBulkDeleteLineItems = '[Shipment Detail] Bulk Action: Update / Delete Validate Line Item',
  validateBulkDeleteLineItemSuccess = '[Shipment Detail] Bulk Action: Update / Delete Validate Line Item Success',
  validateBulkDeleteLineItemsError = '[Shipment Detail] Bulk Action: Update / Delete Validate Line Item Error',

  PopulateAvailableRoutes = '[Shipment Detail] Populate Available Routes',
  PopulateAvailableRoutesSuccess = '[Shipment Detail] Populate Available Routes Success',
  PopulateAvailableRoutesError = '[Shipment Detail] Populate Available Routes Error',

  SetStop = '[Shipment Detail] Set Stop',
  SetRoute = '[Shipment Detail] Set Route',
  SetPurchaseOrderNumber = '[Shipment Detail] Set Purchase Order Number',
  SetShipmentStatus = '[Shipment Detail] Set Shipment Status',

  OpenEditStatusModal = '[Shipment Detail] Open Edit Status Modal',
  CloseEditStatusModal = '[Shipment Detail] Close Edit Status Modal',

  ChangeShipmentStatus = '[Shipment Detail] Change Shipment Status',
  ChangeShipmentStatusError = '[Shipment Detail] Change Shipment Status Error',
  ChangeShipmentStatusSuccess = '[Shipment Detail] Change Shipment Status Success',

  ToastAction = '[Order Detail] Show Toast',
  ToggleEditMode = '[Shipment Detail] Toggle Edit Mode',
  EditLineItem = '[Shipment Detail] Edit Line Item',
  EditLineItemSuccess = '[Shipment Detail] Edit Line Item Success',
  EditLineItemError = '[Shipment Detail] Edit Line Item Error',

  ClearGeneralError = '[Shipment Detail] Clear Edit Error Banner',
  InitializeForm = '[Shipment Detail] Edit Form Created',
  OnNavigationWithPendingChanges = '[Shipment Detail] On Navigation with Pending Changes',

  HeaderNoteChanged = '[Shipment Detail] Header Note Changed',

  ExportShipment = '[Shipment Detail] Export Shipment',
  ExportShipmentSuccess = '[Shipment Detail] Export Shipment Success',
  ExportShipmentError = '[Shipment Detail] Export Shipment Error',

  OpenCloneModal = '[Shipment Detail] Open Clone Modal',
  CloseCloneModal = '[Shipment Detail] Close Clone Modal',

  CloneShipment = '[Shipment Detail] Clone Shipment',
  CloneShipmentSuccess = '[Shipment Detail] Clone Shipment Success',
  CloneShipmentError = '[Shipment Detail] Clone Shipment Error',

  GetClonedShipmentDraftID = '[Shipment Detail] Get Cloned Shipment Draft ID',
  GetClonedShipmentDraftIDSuccess = '[Shipment Detail] Get Cloned Shipment Draft ID Success',
  GetClonedShipmentDraftIDError = '[Shipment Detail] Get Cloned Shipment Draft ID Error',

  CloneShipmentByCustomer = '[Shipment Detail] Clone Shipment By Customer',
  CloneShipmentByCustomerSuccess = '[Shipment Detail] Clone Shipment By Customer Success',
  CloneShipmentByCustomerError = '[Shipment Detail] Clone Shipment By Customer Error',

  GetClonedShipmentDraftIDForMultipleCustomers = '[Shipment Detail] Get Cloned Shipment Draft ID For Multiple Customers',
  GetClonedShipmentDraftIDForMultipleCustomersSuccess = '[Shipment Detail] Get Cloned Shipment Draft ID For Multiple Customers Success',
  GetClonedShipmentDraftIDForMultipleCustomersError = '[Shipment Detail] Get Cloned Shipment Draft ID For Multiple Customers Error',

  ClearCloneToMultipleCustomersInfoBanner = '[Shipment Detail] Clear Clone To Multiple Customers Info Banner',
  ClearCloneToMultipleCustomersErrorBanner = '[Shipment Detail] Clear Clone To Multiple Customers Error Banner',

  CloneShipmentByChain = '[Shipment Detail] Clone Shipment By Chain',
  CloneShipmentByChainSuccess = '[Shipment Detail] Clone Shipment By Chain Success',
  CloneShipmentByChainError = '[Shipment Detail] Clone Shipment By Chain Error',

  DeleteShipmentEllipsesActionClicked = '[Shipment Detail] Delete Shipment Ellipses Action Clicked',
  CloseDeleteShipmentModal = '[Shipment Detail] Close Delete Shipment Modal',
  DeleteShipment = '[Shipment Detail] Delete Shipment',
  DeleteShipmentSuccess = '[Shipment Detail] Delete Shipment Success',
  DeleteShipmentError = '[Shipment Detail] Delete Shipment Error',
}

export const loadShipmentDetail = createAction(
  ShipmentDetailActions.LoadShipmentDetail,
  props<{ id: string, dc: string, orderType: string }>()
);
export const loadShipmentDetailSuccess = createAction(
  ShipmentDetailActions.LoadShipmentDetailSuccess,
  props<{ shipment: ShipmentDetail }>()
);
export const loadShipmentDetailError = createAction(
  ShipmentDetailActions.LoadShipmentDetailError,
);
export const saveChanges = createAction(
  ShipmentDetailActions.SaveChanges,
);
export const saveChangesSuccess = createAction(
  ShipmentDetailActions.SaveChangesSuccess,
);
export const saveChangesError = createAction(
  ShipmentDetailActions.SaveChangesError,
  props<{error: any}>()
);
export const attemptCancelEdit = createAction(
  ShipmentDetailActions.AttemptCancelEdit,
);
export const confirmCancelEdit = createAction(
  ShipmentDetailActions.ConfirmCancelEdit,
);
export const showUnsavedChangesModal = createAction(
  ShipmentDetailActions.ShowUnsavedChangesModal,
);
export const hideUnsavedChangesModal = createAction(
  ShipmentDetailActions.HideUnsavedChangesModal,
);
export const toggleNavigationPanel = createAction(
  ShipmentDetailActions.ToggleNavigationPanel
);
export const setCurrentTab = createAction(
  ShipmentDetailActions.SetCurrentTab,
  props<{ tab: ShipmentDetailTabs }>()
);
export const loadLineItems = createAction(
  ShipmentDetailActions.LoadLineItems,
);
export const loadQuantityOnHandSuccess = createAction(
  ShipmentDetailActions.LoadQuantityOnHandSuccess,
  props<{quantityOnHand: any}>()
);
export const loadLineItemsSuccess = createAction(
  ShipmentDetailActions.LoadLineItemsSuccess,
  props<{lineItems: Payload<Array<LineItem>>}>()
);
export const loadLineItemsError = createAction(
  ShipmentDetailActions.LoadLineItemsError
);
export const lineItemSortChanged = createAction(
  ShipmentDetailActions.LineItemSortChanged,
  props<{sortName: number, orderDirection: number}>()
);
export const lineItemListSearchChange = createAction(
  ShipmentDetailActions.LineItemListSearchChange,
  props<{ search: string }>()
);
export const clearLineItemListSearch = createAction(
  ShipmentDetailActions.ClearLineItemListSearch
);
export const paginationStateChanged = createAction(
  ShipmentDetailActions.PaginationStateChanged,
  props<{payload: GridConfig}>()
);
export const changeTabLineItem = createAction(
  ShipmentDetailActions.ChangeTabLineItem,
  props<{selectedTab: ShipmentDetailLineItemTabId}>()
);
export const loadLineItemAvailableCount = createAction(
  ShipmentDetailActions.LoadLineItemAvailableCount
);
export const loadLineItemAvailableCountSuccess = createAction(
  ShipmentDetailActions.LoadLineItemAvailableCountSuccess,
  props<{ counts: Array<Payload<Array<LineItem>>> }>()
);
export const loadLineItemAvailableCountError = createAction(
  ShipmentDetailActions.LoadLineItemAvailableCountError
);
// Select Line Item Actions
export const toggleLineItemSelect = createAction(
  ShipmentDetailActions.ToggleLineItemSelect,
  props<{selectedItem: LineItem}>()
);
export const toggleLineItemSelectAll = createAction(
  ShipmentDetailActions.ToggleLineItemSelectAll,
);
// Edit Line Item Actions
export const toggleEditMode = createAction(
  ShipmentDetailActions.ToggleEditMode
);
export const editLineItem = createAction(
  ShipmentDetailActions.EditLineItem,
  props<{ shipment: ShipmentDetail, lineItem: LineItem }>()
);
export const editLineItemSuccess = createAction(
  ShipmentDetailActions.EditLineItemSuccess,
  props<{ response: any, quantity: number, oldQuantity: number, lineItem: LineItem}>()
);
export const editLineItemError = createAction(
  ShipmentDetailActions.EditLineItemError,
  props<{ error: any, lineItem: LineItem }>()
);
// validations
export const validateItemUpc = createAction(
  ShipmentDetailActions.ValidateItemUpc,
  props<{ searchString: string }>()
);
export const validateItemUpcSuccess = createAction(
  ShipmentDetailActions.ValidateItemUpcSuccess,
  props<{ errorMessage: string }>()
);
export const validateItemUpcError = createAction(
  ShipmentDetailActions.ValidateItemUpcError,
);
  export const validateDuplicateItem = createAction(
  ShipmentDetailActions.ValidateDuplicateItem,
  props<{ itemData: ItemDataObject }>()
);
export const validateDuplicateItemError = createAction(
  ShipmentDetailActions.ValidateDuplicateItemError,
  props<{ error: any }>()
);
export const validateItemDept = createAction(
  ShipmentDetailActions.ValidateItemDept,
  props<{ itemValidation: ItemValidation }>()
);
export const validateItemDeptSuccess = createAction(
  ShipmentDetailActions.ValidateItemDeptSuccess,
  props<{ itemValidation: ItemValidation }>()
);
export const validateItemDeptError = createAction(
  ShipmentDetailActions.ValidateItemDeptError,
  props<{ itemValidation: ItemValidation }>()
);
// quick add validation
export const validateAddLineItem = createAction(
  ShipmentDetailActions.ValidateAddLineItem,
  props<{ item: ItemDataObject, quantity: number }>()
);
export const validateAddLineItemSuccess = createAction(
  ShipmentDetailActions.ValidateAddLineItemSuccess,
  props<{
    response: ShipmentDetail,
    item: any,
    responseItem: any,
    quantityInEaches: number,
    quantityInCases: number
  }>()
);
export const validateAddLineItemError = createAction(
  ShipmentDetailActions.ValidateAddLineItemError,
  props<{ error: any, item: ItemDataObject }>()
);
// update/delete validation
export const validateUpdateLineItem = createAction(
  ShipmentDetailActions.validateUpdateLineItem,
  props<{ item: LineItem }>()
);
export const validateUpdateLineItemSuccess = createAction(
  ShipmentDetailActions.validateUpdateLineItemSuccess,
  props<{response: ShipmentDetail, item: any, responseItem: any, quantityInEaches: number}>()
);
export const validateUpdateLineItemSuccessWithErrors = createAction(
  ShipmentDetailActions.validateUpdateLineItemSuccessWithErrors,
  props<{ item: any, responseItem: any, quantityInEaches: number}>()
);
export const validateUpdateLineItemError = createAction(
  ShipmentDetailActions.validateUpdateLineItemError,
  props<{ error: any, item: LineItem}>()
);
export const validateDeleteLineItem = createAction(
  ShipmentDetailActions.validateDeleteLineItem,
  props<{ item: LineItem }>()
);
export const validateDeleteLineItemSuccess = createAction(
  ShipmentDetailActions.validateDeleteLineItemSuccess,
  props<{response: ShipmentDetail, item: LineItem | GhostLineItem}>()
);
export const validateDeleteLineItemError = createAction(
  ShipmentDetailActions.validateDeleteLineItemError,
  props<{ error: any, item: LineItem}>()
);
export const undoDeleteLineItem = createAction(
  ShipmentDetailActions.UndoDeleteLineItem,
  props<{ deletedItem: LineItem }>()
);
export const undoDeleteLineItemSuccess = createAction(
  ShipmentDetailActions.UndoDeleteLineItemSuccess,
  props<{ response: ShipmentDetail }>()
);
export const undoDeleteLineItemError = createAction(
  ShipmentDetailActions.UndoDeleteLineItemError,
  props<{ error: any }>()
);
export const validateBulkDeleteLineItems = createAction(
  ShipmentDetailActions.validateBulkDeleteLineItems,
);
export const validateBulkDeleteLineItemsSuccess = createAction(
  ShipmentDetailActions.validateBulkDeleteLineItemSuccess,
  props<{response: ShipmentDetail}>()
);
export const validateBulkDeleteLineItemsError = createAction(
  ShipmentDetailActions.validateBulkDeleteLineItemsError,
);
// Edit Route/Stop
export const populateAvailableRoutes = createAction(
  ShipmentDetailActions.PopulateAvailableRoutes,
);
export const populateAvailableRoutesSuccess = createAction(
  ShipmentDetailActions.PopulateAvailableRoutesSuccess,
  props<{availableRoutes: Array<string>}>()
);
export const populateAvailableRoutesError = createAction(
  ShipmentDetailActions.PopulateAvailableRoutesError,
  props<{error: any}>()
);
export const setRoute = createAction(
  ShipmentDetailActions.SetRoute,
  props<{route: string}>()
);
export const setStop = createAction(
  ShipmentDetailActions.SetStop,
  props<{stop: number}>()
);
export const setPurchaseOrderNumber = createAction(
  ShipmentDetailActions.SetPurchaseOrderNumber,
  props<{poNumber: any}>()
);
export const setShipmentStatus = createAction(
  ShipmentDetailActions.SetShipmentStatus,
  props<{statusName: string, statusId: string}>()
);
export const openEditStatusModal = createAction(
  ShipmentDetailActions.OpenEditStatusModal
);
export const closeEditStatusModal = createAction(
  ShipmentDetailActions.CloseEditStatusModal
);
export const changeShipmentStatus = createAction(
  ShipmentDetailActions.ChangeShipmentStatus,
  props<{form: EditShipmentStatusForm}>()
);
export const changeShipmentStatusSuccess = createAction(
  ShipmentDetailActions.ChangeShipmentStatusSuccess,
  props<{ statusName: string, statusId: string }>()
);
export const changeShipmentStatusError = createAction(
  ShipmentDetailActions.ChangeShipmentStatusError,
  props<{error: any}>()
);

// toasts
export const toastAction = createAction(
  ShipmentDetailActions.ToastAction
);

// clear
export const clearSaveSuccess = createAction(
  ShipmentDetailActions.ClearSaveSuccess,
);
export const clearSaveError = createAction(
  ShipmentDetailActions.ClearSaveError,
);
// submit
export const clearGeneralError = createAction(
  ShipmentDetailActions.ClearGeneralError
);
// form controls
export const initializeEditForm = createAction(
  ShipmentDetailActions.InitializeForm,
  props<{ form: EditShipmentEditForm }>()
);
/** On Navigation with Pending Changes */
export const onNavigationWithPendingChanges = createAction(
  ShipmentDetailActions.OnNavigationWithPendingChanges,
  props<{ url: string; targetTab?: ShipmentDetailTabs }>()
);
export const syncShipmentDetail = createAction(
  ShipmentDetailActions.SyncShipmentDetail,
);
export const syncShipmentDetailSuccess = createAction(
  ShipmentDetailActions.SyncShipmentDetailSuccess,
  props<{ shipment: ShipmentDetail }>()
);
export const syncShipmentDetailError = createAction(
  ShipmentDetailActions.SyncShipmentDetailError,
);
export const headerNoteChanged = createAction(
  ShipmentDetailActions.HeaderNoteChanged,
  props<{ note: string }>()
);

export const exportShipment = createAction(
  ShipmentDetailActions.ExportShipment,
);
export const exportShipmentSuccess = createAction(
  ShipmentDetailActions.ExportShipmentSuccess,
);
export const exportShipmentError = createAction(
  ShipmentDetailActions.ExportShipmentError,
);
export const closeExportErrorAlert = createAction(
  '[Shipment Detail] Close Export Error Banner Alert',
);
export const closeDeleteErrorAlert = createAction(
  '[Shipment Detail] Close Delete Error Banner Alert',
);
// Start: Clone Shipment Logic
export const openCloneModal = createAction(
  ShipmentDetailActions.OpenCloneModal
);
export const closeCloneModal = createAction(
  ShipmentDetailActions.CloseCloneModal
);

export const cloneShipment = createAction(
  ShipmentDetailActions.CloneShipment,
  props<{ shipmentToClone: IShipmentsCloneDto }>()
);
export const cloneShipmentSuccess = createAction(
  ShipmentDetailActions.CloneShipmentSuccess,
  props<{ cloneGUID: string }>()
);
export const cloneShipmentError = createAction(
  ShipmentDetailActions.CloneShipmentError,
  props<{ error: any }>()
);

export const getClonedShipmentDraftID = createAction(
  ShipmentDetailActions.GetClonedShipmentDraftID,
);
export const getClonedShipmentDraftIDSuccess = createAction(
  ShipmentDetailActions.GetClonedShipmentDraftIDSuccess,
  props<{ draftOrderId: string }>()
);
export const getClonedShipmentDraftIDError = createAction(
  ShipmentDetailActions.GetClonedShipmentDraftIDError,
  props<{ error: any }>()
);

export const cloneShipmentByCustomer = createAction(
  ShipmentDetailActions.CloneShipmentByCustomer,
  props<{ shipmentToClone: IShipmentsCloneByCustomerDto }>()
);
export const cloneShipmentByCustomerSuccess = createAction(
  ShipmentDetailActions.CloneShipmentByCustomerSuccess,
  props<{ cloneShipmentByCustomerResponseArray: CloneShipmentByCustomerResponse[] }>()
);
export const cloneShipmentByCustomerError = createAction(
  ShipmentDetailActions.CloneShipmentByCustomerError,
  props<{ errors: CloneShipmentByCustomerResponse[] }>()
);

export const getClonedShipmentDraftIDForMultipleCustomers = createAction(
  ShipmentDetailActions.GetClonedShipmentDraftIDForMultipleCustomers,
);
export const getClonedShipmentDraftIDForMultipleCustomersSuccess = createAction(
  ShipmentDetailActions.GetClonedShipmentDraftIDForMultipleCustomersSuccess,
  props<{ cloneGuids: CloneShipmentByCustomerGuid[] }>()
);
export const getClonedShipmentDraftIDForMultipleCustomersError = createAction(
  ShipmentDetailActions.GetClonedShipmentDraftIDForMultipleCustomersError,
  props<{ cloneGuids: CloneShipmentByCustomerGuid[] }>()
);

export const clearCloneToMultipleCustomersInfoBanner = createAction(
  ShipmentDetailActions.ClearCloneToMultipleCustomersInfoBanner,
);
export const clearCloneToMultipleCustomersErrorBanner = createAction(
  ShipmentDetailActions.ClearCloneToMultipleCustomersErrorBanner,
);

export const cloneShipmentByChain = createAction(
  ShipmentDetailActions.CloneShipmentByChain,
  props<{ shipmentToClone: IShipmentsCloneByChainDto }>()
);
export const cloneShipmentByChainSuccess = createAction(
  ShipmentDetailActions.CloneShipmentByChainSuccess,
  props<{ manageProgressGuid: string }>()
);
export const cloneShipmentByChainError = createAction(
  ShipmentDetailActions.CloneShipmentByChainError,
  props<{ error: any }>()
);
// End: Clone Shipment Logic

// Start: Delete Shipment Logic
export const deleteShipmentEllipsesActionClicked = createAction(
  ShipmentDetailActions.DeleteShipmentEllipsesActionClicked
);
export const closeDeleteShipmentModal = createAction(
  ShipmentDetailActions.CloseDeleteShipmentModal
);

export const deleteShipment = createAction(
  ShipmentDetailActions.DeleteShipment,
);
export const deleteShipmentSuccess = createAction(
  ShipmentDetailActions.DeleteShipmentSuccess,
);
export const deleteShipmentError = createAction(
  ShipmentDetailActions.DeleteShipmentError,
  props<{ error: DeleteShipmentResponseObj }>()
);
// End: Delete Shipment Logic

export const openProductQuickViewModal = createAction(
  '[Shipment Detail] Open Product Quick View Modal',
  props<{ upc: string }>()
);

export const showMoreTrackingNumbers = createAction(
  '[Shipment Detail] Show More Tracking Numbers'
);

export const showLessTrackingNumbers = createAction(
  '[Shipment Detail] Show Less Tracking Numbers'
);

export const openOrdersPageInNewTab = createAction(
  '[Shipment Detail] Open Orders Page In New Tab'
);
