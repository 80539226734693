import { createSelector } from "@ngrx/store";
import { State } from "../index";
import * as fromDevice from "./device.reducer";

export const selectDeviceState = (state: State) => state.device;
export const selectDeviceInfo = createSelector(
    selectDeviceState,
    fromDevice.getDeviceInfo,
);
export const selectIsDesktop = createSelector(
    selectDeviceState,
    fromDevice.getIsDesktop,
);
export const selectIsMobile = createSelector(
    selectDeviceState,
    fromDevice.getIsMobile,
);
export const selectIsTablet = createSelector(
    selectDeviceState,
    fromDevice.getIsTablet,
);
export const selectIsDesktopOrTablet = createSelector(
    selectIsDesktop,
    selectIsTablet,
    (isDesktop: boolean, isTablet: boolean) => {
        return isDesktop || isTablet;
    }
);