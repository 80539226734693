import { IChip } from '@kehe/phoenix-chips';
import { IKeheTab } from '@kehe/phoenix-tabs';
import { createAction, props } from '@ngrx/store';
import { PageChangeEvent } from '@progress/kendo-angular-pager';
import { SortDescriptor } from '@progress/kendo-data-query';
import { ProhibitRestrictFilterType } from '../../prohibit-restrict-shared/enum/prohibit-restrict-filter-type.enum';
import { ProhibitRestrictFilterItem } from '../models/prohibit-restrict-filter-item';
import { ProhibitRestrictFilterValues } from '../models/prohibit-restrict-filter-values';
import { ProhibitRestrictListItem } from '../models/prohibit-restrict-list-item';

export enum ProhibitRestictListActions{
    ChangeSelectedTab = '[ProhibitRestrict] Change Selected Tab',
    OpenFilterPanel = '[ProhibitRestrict List] Open Filter Panel',
    CloseFilterPanel = '[ProhibitRestrict List] Close Filter Panel',
    ProhibitRestrictsListFilterClearAll = '[ClearProhibitsRestrictsGuard] Prohibit Restricts List Filter Clear All',
    FilterMultiSelectChanged = '[Filter Side Panel] Filter MultiSelect Changed',
    FilterMultiSelectDataSourceUpdated = '[Filter Side Panel] Filter MultiSelect DataSource Updated',
    FilterMultiSelectDataLoadError = '[Filter Side Panel] Filter MultiSelect DataLoad Error',
    ProhibitRestrictListPageLoaded = '[ProhibitRestrict List] Prohibit Restrict List Page Loaded',
    LoadProhibitRestrictList = '[ProhibitRestrict List] Load Prohibit Restrict List',
    LoadProhibitRestrictListSuccess = '[ProhibitRestrict List] Load Prohibit Restrict List Success',
    LoadProhibitRestrictListError = '[ProhibitRestrict List] Load Prohibit Restrict List Error',
    ProhibitRestrictListPaginationChange = '[ProhibitRestrict List] Prohibit Restrict List Pagination Change',
    ApplyFilter = '[Filter Side Panel] Apply Filter',
    FilterChipsClearAll = '[Filter Chips] Filter Chips Clear All',
    ClearFilterChip = '[Filter Chips] Clear Filter Chip',
    SearchChanged = '[ProhibitRestrict List] Search Changed',
    SortChanged = '[ProhibitRestrict List] Sort Changed',
}

export const changeSelectedTab = createAction(
    ProhibitRestictListActions.ChangeSelectedTab,
    props<{ selectedTab: IKeheTab }>()
);

export const openFilterPanel = createAction(
    ProhibitRestictListActions.OpenFilterPanel
  );

  export const closeFilterPanel = createAction(
    ProhibitRestictListActions.CloseFilterPanel
);

export const prohibitRestrictsListFilterClearAll = createAction(
    ProhibitRestictListActions.ProhibitRestrictsListFilterClearAll
  );

export const filterMultiSelectChanged = createAction(
    ProhibitRestictListActions.FilterMultiSelectChanged,
    props<{ value: string; filterType: ProhibitRestrictFilterType; selectedChains?: ProhibitRestrictFilterItem[] }>()
);

export const filterMultiSelectDataSourceUpdated = createAction(
    ProhibitRestictListActions.FilterMultiSelectDataSourceUpdated,
    props<{ data: ProhibitRestrictFilterItem[], filterType: ProhibitRestrictFilterType }>()
);
export const filterMultiSelectDataLoadError = createAction(
    ProhibitRestictListActions.FilterMultiSelectDataLoadError,
    props<{filterType: ProhibitRestrictFilterType }>()
);
export const prohibitRestrictListPageLoaded = createAction(
    ProhibitRestictListActions.ProhibitRestrictListPageLoaded
);
export const loadProhibitRestrictList = createAction(
    ProhibitRestictListActions.LoadProhibitRestrictList
);
export const loadProhibitRestrictListSuccess = createAction(
    ProhibitRestictListActions.LoadProhibitRestrictListSuccess,
    props<{ availableCount: number, data : ProhibitRestrictListItem[] }>()
);
export const loadProhibitRestrictListError = createAction(
    ProhibitRestictListActions.LoadProhibitRestrictListError
);
export const prohibitRestrictListPaginationChange = createAction(
    ProhibitRestictListActions.ProhibitRestrictListPaginationChange,
    props<{ event: PageChangeEvent }>()
);

export const applyFilter = createAction(
    ProhibitRestictListActions.ApplyFilter,
    props<{ selectedValues: ProhibitRestrictFilterValues }>()
);

export const filterChipsClearAll = createAction(
    ProhibitRestictListActions.FilterChipsClearAll
);

export const clearFilterChip = createAction(
    ProhibitRestictListActions.ClearFilterChip,
    props<{ chip: IChip }>()
);

export const searchChanged = createAction(
    ProhibitRestictListActions.SearchChanged,
    props<{ search: string }>()
);

export const sortChanged = createAction(
    ProhibitRestictListActions.SortChanged,
    props<{ sort: SortDescriptor[] }>()
);
