<div class="home-page-container">

    <kehe-phoenix-welcome-banner [disableAnimation]="true">
      <ng-template #titleBlock>
          Welcome to KeHE CONNECT Enterprise<em class="trademark-logo far fa-registered"></em>!
      </ng-template>
      <ng-template #subheaderBlock>
        <p class="font-size-14">Fueled by Innovation, Teamwork and Collaboration</p>
      </ng-template>
    </kehe-phoenix-welcome-banner>

  <app-work-queue-banner
    *ngIf="
      (viewWorkQueueBannerFeatureFlag$ | async) &&
      (canShowWorkQueueBanner$ | async)
    "
  ></app-work-queue-banner>

    <app-announcements-recent *ngIf="viewAnnouncementsFeatureFlag$ | async"
                              class="announcements-container">
    </app-announcements-recent>

    <div class="card-tile-container">
      <div class="kehe-card-banner">
        <div class="card-link-title">
          Resources
        </div>
        <div class="card-link-image"
          style="background-image: url(&quot;https://s3.amazonaws.com/kehe-connect/prod/public_common/supplier-documents.png&quot;); background-position: center center;">
        </div>
        <div class="card-link-subtitle">
          <p>Stay current and download the most recent important Documents, Newsletters, Calendars, Trainings, Contracts
            and Publications.</p>
        </div>
        <div class="card-link-actions">
          <kehe-phoenix-button classAttr="btn-link btn-medium btn-block" iconRightClassAttr="fa fa-chevron-right"
            (click)="navigateToResourcesLib()">
            View Resources
          </kehe-phoenix-button>
        </div>
      </div>

        <!-- <div class="kehe-card-banner">
            <div class="card-link-title">
                Insights
            </div>
            <div class="card-link-image" style="background-image: url(&quot;https://kehe-connect.s3.amazonaws.com/prod/public_common/understand-your-business-better-bg.png&quot;); background-position: center center;"></div>
            <div class="card-link-subtitle">
                <p>Understand our suppliers and customers better by keeping up to date with recent insights.</p>
            </div>
            <div class="card-link-actions">
                <kehe-phoenix-button classAttr="btn-link btn-medium btn-block" iconRightClassAttr="fa fa-chevron-right" (click)="navigate(_insightsUrl)">
                    Return on Promo Investment
                </kehe-phoenix-button>
                <kehe-phoenix-button classAttr="btn-link btn-medium btn-block" iconRightClassAttr="fa fa-chevron-right" (click)="navigate(_suggestedOrderUrl)">
                    Suggested Order
                </kehe-phoenix-button>
            </div>
        </div> -->
    </div>

    <h3 class="trending-videos-header">Hear From KeHE</h3>
    <div class="trending-videos-container">
        <div class="trending-videos-image"></div>
        <div class="trending-videos-content">
            <span class="trending-videos-title">Get KeHE updates by viewing our trending videos</span>
            <kehe-phoenix-button (click)="navigate('https://web.microsoftstream.com/'); handleClickVideo()" classAttr="btn-default btn-medium" iconRightClassAttr="fa fa-chevron-right">
                View Videos
            </kehe-phoenix-button>
        </div>
    </div>

    <h3 class="helpful-links-title">Helpful Links</h3>
    <div class="helpful-links-container row">
        <div class="col-lg-3 col-md-6 col-6 button-link">
          <kehe-phoenix-button classAttr="btn-image col-12" [imageUrl]="'https://s3.amazonaws.com/kehe-connect/prod/public_common/15817_logo_betterworks.png'" (click)="navigate('https://app.betterworks.com/app/splash/#/signin?next=home')">Betterworks</kehe-phoenix-button>
        </div>
        <div class="col-lg-3 col-md-6 col-6 button-link">
            <kehe-phoenix-button classAttr="btn-image col-12" [imageUrl]="'https://s3.amazonaws.com/kehe-connect/prod/public_common/Dayforce-Logo.png'" (click)="navigate('https://sso.dayforcehcm.com/SSOLogin.aspx')"><span class="mx-4">Dayforce</span></kehe-phoenix-button>
        </div>
        <div class="col-lg-3 col-md-6 col-6 button-link">
            <kehe-phoenix-button classAttr="btn-image col-12" [imageUrl]="'https://s3.amazonaws.com/kehe-connect/prod/public_common/keheinsider_logo.png'" (click)="navigate('https://central.kehe.com/')">KeHE Central</kehe-phoenix-button>
        </div>
        <div class="col-lg-3 col-md-6 col-6 button-link">
            <kehe-phoenix-button classAttr="btn-image col-12" [imageUrl]="'https://s3.amazonaws.com/kehe-connect/prod/public_common/kehesharepoint_logo.png'" (click)="navigate('https://kehe.sharepoint.com/_layouts/15/sharepoint.aspx')">KeHE Sharepoint</kehe-phoenix-button>
        </div>
        <div class="col-lg-3 col-md-6 col-6 button-link">
            <kehe-phoenix-button classAttr="btn-image col-12" [imageUrl]="'https://s3.amazonaws.com/kehe-connect/prod/public_common/chromeriver_logo.png'" (click)="navigate('https://app.chromeriver.com/login')">Chrome River</kehe-phoenix-button>
        </div>
        <div class="col-lg-3 col-md-6 col-6 button-link">
            <kehe-phoenix-button classAttr="btn-image col-12" [imageUrl]="'https://s3.amazonaws.com/kehe-connect/prod/public_common/helpdesk_logo.png'" (click)="navigate('https://keheco.theitsupportcenter.net')">Helpdesk</kehe-phoenix-button>
        </div>
        <div class="col-lg-3 col-md-6 col-6 button-link">
            <kehe-phoenix-button classAttr="btn-image col-12" [imageUrl]="'https://s3.amazonaws.com/kehe-connect/prod/public_common/salesconnectiontoolkit_logo.png'" (click)="navigate('https://kehe.sharepoint.com/sites/MerchandiseSolutions2')">Sales Connections</kehe-phoenix-button>
        </div>
        <div class="col-lg-3 col-md-6 col-6 button-link">
            <kehe-phoenix-button classAttr="btn-image col-12" [imageUrl]="'https://s3.amazonaws.com/kehe-connect/prod/public_common/powerbi_logo.png'" (click)="navigate('https://app.powerbi.com/')">Power BI</kehe-phoenix-button>
        </div>
    </div>
</div>
