import { Environment } from "../../environments/environment-enum";
export enum ReportName {
  SpinsDataByCategory = 'Spins Data by Category',
  CategoryLevelSkuProdByPeriod = 'Category Level SKU Prod By Period',
  supplierSolutionSales = 'Supplier Solutions Sales',
  GBBSalesandVendor = 'GBB Sales and Vendor Support Status by Category',
  SKUOptESNReview= 'SKU opt ESN Review',
  activeRankingYOYEnterprise = 'Active Ranking YOY Enterprise',
  GBBDCItemLevelSalesRanking = 'GBB DC Item Level Sales Ranking',
  activeRankingYOYdc = 'Active Items Ranking YOY DC',
  GBBEnterpriseLevelScoreandTier = 'GBB Enterprise Level Score and Tier',
  DcGBB = 'DC GBB',
  SelectReport = 'Select a Report'
}

export const ssrsReportIds: Map<ReportName, Map<Environment, string>> = new Map([
  [ReportName.SpinsDataByCategory, new Map([
    [Environment.LOCAL, 'Vi9OYlI4bktDZWs9'],
    [Environment.DEV, 'Vi9OYlI4bktDZWs9'],
    [Environment.UAT, 'Z3pUSFg0RndUb3M9'],
    [Environment.PROD, 'bnc3ZS9rb05rTjg9'],
  ])],
  [ReportName.CategoryLevelSkuProdByPeriod, new Map([
    [Environment.LOCAL, 'NjlPeWRuYTVtUDg9'],
    [Environment.DEV, 'NjlPeWRuYTVtUDg9'],
    [Environment.UAT, 'VnM0dXV0dTMzNkk9'],
    [Environment.PROD, 'YlgrR29RSVI2K3M9'],
  ])],
  [ReportName.supplierSolutionSales, new Map([
    [Environment.LOCAL, 'QUZKUU9ZT00vOHc9'],
    [Environment.DEV, 'QUZKUU9ZT00vOHc9'],
    [Environment.UAT, 'S0tqQTZLL3VuVTQ9'],
    [Environment.PROD, 'VktERmlyT25IUE09'],
  ])],
  [ReportName.GBBSalesandVendor, new Map([
    [Environment.LOCAL, 'OUp2Tyt1aXA1M0k9'],
    [Environment.DEV, 'OUp2Tyt1aXA1M0k9'],
    [Environment.UAT, 'SFArVGVkdnZVd2c9'],
    [Environment.PROD, 'bkFmenZUVFpDOVE9'],
  ])],
  [ReportName.SKUOptESNReview, new Map([
    [Environment.LOCAL, 'Y0pxQ1NxVy84MVk9'],
    [Environment.DEV, 'Y0pxQ1NxVy84MVk9'],
    [Environment.UAT, 'emJQRUpqTUZoaEk9'],
    [Environment.PROD, 'UVArSldTVmVpN1U9'],
  ])],
  [ReportName.activeRankingYOYEnterprise, new Map([
    [Environment.LOCAL, 'b20rTzFpdkp3NjQ9'],
    [Environment.DEV, 'b20rTzFpdkp3NjQ9'],
    [Environment.UAT, 'UDYyREw2RUplUU09'],
    [Environment.PROD, 'MlFaak5laE5NZGM9'],
  ])],
  [ReportName.GBBDCItemLevelSalesRanking, new Map([
    [Environment.LOCAL, 'NGNnSTNWSVkybGs9'],
    [Environment.DEV, 'NGNnSTNWSVkybGs9'],
    [Environment.UAT, 'cXJCTTFDTGVhMDA9'],
    [Environment.PROD, 'eGdVSTdrbFAwdXM9'],
  ])],
  [ReportName.activeRankingYOYdc, new Map([
    [Environment.LOCAL, 'U0ozbEJ3UkowWDA9'],
    [Environment.DEV, 'U0ozbEJ3UkowWDA9'],
    [Environment.UAT, 'UCtXUnJGaXZVNVk9'],
    [Environment.PROD, 'QUZKUU9ZT00vOHc9'],
  ])],
  [ReportName.GBBEnterpriseLevelScoreandTier, new Map([
    [Environment.LOCAL, 'NzZmcnBwOUVja2M9'],
    [Environment.DEV, 'NzZmcnBwOUVja2M9'],
    [Environment.UAT, 'dFh1ZzNPMVNuSVU9'],
    [Environment.PROD, 'OUp2Tyt1aXA1M0k9'],
  ])],
  [ReportName.DcGBB, new Map([
    [Environment.LOCAL, 'NE5pVzZlQ0RmOWc9'],
    [Environment.DEV, 'NE5pVzZlQ0RmOWc9'],
    [Environment.UAT, 'ZUZ1L2FIcEVYYzA9'],
    [Environment.PROD, 'Y0pxQ1NxVy84MVk9'],
  ])]
]);

