import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import * as supplierDocumentsActions from './supplier-documents.actions';
import { SupplierDocumentsService } from '../services/supplier-documents.service';
import { select, Store } from '@ngrx/store';
import {
  selectDeleteDocument,
  selectDocuments,
  selectDocumentTypes,
  selectSupplierDetail,
} from './supplier-documents.selectors';
import { of } from 'rxjs';
import { DocumentType } from '../models/document-type';
import { Document } from '../models/document';
import { DocumentCreateRequest } from '../models/document-create-request';
import { DocumentStatus } from '../models/document-statuses';
import { Constants } from '../../../constants/constants';
import { ADDENDUM_TEMPLATES } from '@app/modules/supplier-documents/constants/document-constants';
import { PhoenixToastService } from '@kehe/phoenix-notifications';
import { PendoEvent } from '../models/pendo-event.interface';
import { PendoTrackEvents } from '../models/pendo-track-events.enum';
import { selectIsFeatureFlagOn } from "@app/shared/state/feature-flag/feature-flag.selectors";
import { FeatureFlags } from "@app/feature-flag";

@Injectable()
export class SupplierDocumentsEffects {

  constructor(
    private _store: Store,
    private _actions$: Actions,
    private _supplierDocumentsService: SupplierDocumentsService,
    private _toastService: PhoenixToastService,
  ) { }

  /* Load Documents */
  loadDocuments$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        supplierDocumentsActions.loadSupplierDocumentsData,
        supplierDocumentsActions.loadDocuments,
        supplierDocumentsActions.uploadAndSaveDocumentSuccess,
      ),
      withLatestFrom(
        this._store.pipe(select(selectSupplierDetail)),
        this._store.select(selectIsFeatureFlagOn(FeatureFlags.PurchasingInfoToSupplierInfo.key)),
      ),
      switchMap(([_, supplierDetails, purchasingInfoToSupplierInfoFF]) => {
        return this._supplierDocumentsService.getDocuments(supplierDetails.esn).pipe(
          map((documents) =>
            supplierDocumentsActions.loadDocumentsSuccess({ documents, purchasingInfoToSupplierInfoFF })
          ),
          catchError(() => of(supplierDocumentsActions.loadDocumentsError()))
        );
      })
    )
  );

  deleteDocuments$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        supplierDocumentsActions.deleteDocument,
      ),
      withLatestFrom(
        this._store.pipe(select(selectDeleteDocument)),
      ),
      switchMap(([_, document]) => {
        return this._supplierDocumentsService.deleteDocument(document).pipe(
          map(() =>
            supplierDocumentsActions.deleteDocumentSuccess({ document })
          ),
          catchError(() => of(supplierDocumentsActions.deleteDocumentError()))
        );
      })
    )
  );

  loadDocumentTypes$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        supplierDocumentsActions.loadSupplierDocumentsData,
      ),
      withLatestFrom(
        this._store.pipe(select(selectSupplierDetail)),
        this._store.select(selectIsFeatureFlagOn(FeatureFlags.PurchasingInfoToSupplierInfo.key)),
      ),
      filter(([_, supplierDetails, purchasingInfoToSupplierInfoFF]) =>
        !!supplierDetails.inviteReasonType && !!supplierDetails.supplierType),
      switchMap(([_, supplierDetails, purchasingInfoToSupplierInfoFF]) => {
        return this._supplierDocumentsService.getDocumentTypes(supplierDetails.inviteReasonType,
          supplierDetails.supplierType).pipe(
          map(documentTypes => {
            return supplierDocumentsActions.loadDocumentTypesSuccess({ documentTypes, purchasingInfoToSupplierInfoFF });
          }),
          catchError(() => of(supplierDocumentsActions.loadDocumentTypesError()))
        );
      })
    )
  );

  updateDocumentTypesUploadStatus$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        supplierDocumentsActions.loadDocumentsSuccess,
        supplierDocumentsActions.loadDocumentTypesSuccess,
        supplierDocumentsActions.deleteDocumentSuccess,
        supplierDocumentsActions.updateDocumentStatusSuccess,
      ),
      withLatestFrom(
        this._store.pipe(select(selectDocuments)),
        this._store.pipe(select(selectDocumentTypes)),
      ),
      map(([_, documents, documentTypes]) => {
        return supplierDocumentsActions.updateDocumentTypeUploadStatus(
          { documentTypes: this.updateDocumentTypeUploadStatus(documents, documentTypes) }
        );
      }
      )
    )
  );

  downloadDocument$ = createEffect(() =>
    this._actions$.pipe(
      ofType(supplierDocumentsActions.downloadDocument),
      withLatestFrom(
        this._store.pipe(select(selectSupplierDetail)),
      ),
      switchMap(([action, supplier]) => {
        return this._supplierDocumentsService.getPreSignedDownloadURL(supplier.esn, action.document.uniqueId).pipe(
          map((url) => {
            const link = document.createElement('a');
            if (typeof link.download !== 'undefined') {
              link.href = url;
              link.download = action.document.filename;
              if (action.target) {
                link.target = action.target;
              }
              link.click();
            } else {
              window.open(url, '_blank'); // so that it opens new tab for IE11
            }
            return supplierDocumentsActions.downloadDocumentCompleted({ document: action.document });
          }
          ),
          catchError(() => of(supplierDocumentsActions.downloadDocumentFailed()))
        );
      })
    )
  );
  updateDocumentStatus$ = createEffect(() =>
    this._actions$.pipe(
      ofType(supplierDocumentsActions.updateDocumentStatus),
      withLatestFrom(
        this._store.pipe(select(selectSupplierDetail)),
      ),
      switchMap(([action, supplier]) => {
        const event: PendoEvent = {
          name: PendoTrackEvents.UpdateSupplierDocumentStatus,
          eventProperties: {
            esn: supplier.esn,
            biTimestamp: Date.now(),
            documentStatus: action.document.status,
            documentType: action.document.type,
            requesterType: supplier.requesterType,
            isInternational: supplier.isInternational,
            invitedDate: supplier.invitedDate,
            isDpiInitiated: supplier.isDpiInitiated,
            cmName: supplier.cmName
          }
        }
        return this._supplierDocumentsService.updateDocumentStatus(supplier.esn, action.document).pipe(
          map(() => supplierDocumentsActions.updateDocumentStatusSuccess({ document: action.document, pendoEvent: event })),
          catchError(error => of(supplierDocumentsActions.updateDocumentStatusError(
            { errorCode: error.status, document: action.document }
          )))
        );
      })
    )
  );
  downloadPrivateAssets$ = createEffect(() =>
    this._actions$.pipe(
      ofType(supplierDocumentsActions.downloadPrivateAssets),
      switchMap((action) => {
        return this._supplierDocumentsService.getPrivateAssetsPreSignedDownloadURL(action.fileName).pipe(
          map((url) => {
            window.location.href = url;
          })
        );
      })
    ), { dispatch: false }
  );

  downloadNewContractTemplate$ = createEffect(() =>
    this._actions$.pipe(
      ofType(supplierDocumentsActions.downloadNewContractTemplate),
      withLatestFrom(
        this._store.pipe(select(selectSupplierDetail)),
      ),
      switchMap(([_, supplierDetails]) => {
        const fileName = ADDENDUM_TEMPLATES[supplierDetails.supplierType];
        return of(supplierDocumentsActions.downloadPrivateAssets({ fileName }));
      })
    )
  );
  /* Load Documents */

  /* Upload Documents */

  getUploadUrl$ = createEffect(() =>
    this._actions$.pipe(
      ofType(supplierDocumentsActions.uploadAndSaveDocument),
      withLatestFrom(this._store.pipe(select(selectSupplierDetail))),
      switchMap(([action, supplierDetail]) => {
        return this._supplierDocumentsService.getUploadUrl(supplierDetail.esn, action.uploadDocumentRequest.file.name).pipe(
          map((uploadURLResponse) => supplierDocumentsActions.uploadDocumentToS3({
            uploadDocumentRequest: action.uploadDocumentRequest,
            documentUploadUrlResponse: uploadURLResponse,
          })),
          catchError(() => of(supplierDocumentsActions.uploadAndSaveDocumentError()))
        );
      })
    )
  );

  uploadDocumentToS3$ = createEffect(() =>
    this._actions$.pipe(
      ofType(supplierDocumentsActions.uploadDocumentToS3),
      switchMap((action) => {
        return this._supplierDocumentsService
          .uploadToS3(action.uploadDocumentRequest.file.rawFile, action.documentUploadUrlResponse.url).pipe(
            map(() => supplierDocumentsActions.createDocument(action)),
            catchError(() => of(supplierDocumentsActions.uploadAndSaveDocumentError()))
          );
      })
    )
  );

  createDocument$ = createEffect(() =>
    this._actions$.pipe(
      ofType(supplierDocumentsActions.createDocument),
      withLatestFrom(
        this._store.pipe(select(selectSupplierDetail)),
      ),
      switchMap(([action, supplierDetail]) => {
        const createDocumentRequest: DocumentCreateRequest = {
          documentTypeId: action.uploadDocumentRequest.documentTypeId,
          esn: supplierDetail.esn,
          originalFilename: action.uploadDocumentRequest.file.name,
          uniqueId: action.documentUploadUrlResponse.uniqueId,
          expires: action.uploadDocumentRequest.expires
        };
        return this._supplierDocumentsService.createDocument(createDocumentRequest).pipe(
          map((document) => supplierDocumentsActions.uploadAndSaveDocumentSuccess({ document })),
          catchError(() => of(supplierDocumentsActions.uploadAndSaveDocumentError()))
        );
      })
    )
  );
    
    updateSIFUpload$ = createEffect(() =>
      this._actions$.pipe(
        ofType(supplierDocumentsActions.uploadAndSaveDocumentSuccess),
        filter((action) => action.document.typeId === Constants.SupplierInfoDocumentTypeId),
        withLatestFrom(this._store.pipe(select(selectSupplierDetail))),
        map(([action, supplier]) => {
          const event: PendoEvent = {
            name: PendoTrackEvents.UpdateSupplierDocumentStatus,
            eventProperties: {
              esn: supplier.esn,
              biTimestamp: Date.now(),
              documentStatus: action.document?.status,
              documentType: DocumentStatus.InReview,
              requesterType: supplier.requesterType,
              isInternational: supplier.isInternational,
              invitedDate: supplier.invitedDate,
              isDpiInitiated: supplier.isDpiInitiated,
              cmName: supplier.cmName
            }
          }
          this._store.dispatch(supplierDocumentsActions.updateDocumentStatusSuccess({
            document: { ...action.document, status: DocumentStatus.InReview },
            pendoEvent: event
          }));
        })
      ), { dispatch: false }
    );

    uploadSuccessToast$ = createEffect(() =>
      this._actions$.pipe(
        ofType(supplierDocumentsActions.uploadAndSaveDocumentSuccess),
        filter((action) => action.document.typeId === Constants.PurchasingInfoDocumentTypeId 
        || action.document.typeId === Constants.SupplierInfoDocumentTypeId
        || action.document.typeId === Constants.RetailerRequestedDetailsDocumentTypeId),
        tap(() => this._toastService.showSuccessToast('Document uploaded successfully!'))
      ), { dispatch: false }
    );

  /* Upload Documents */

  /* Utils Methods */
  private updateDocumentTypeUploadStatus(documents: Document[], documentTypes: DocumentType[]): DocumentType[] {
    documentTypes.forEach(documentType => {
      const doc = documents.find(e => e.type === documentType.name);
      documentType.isUploaded = doc !== null && doc !== undefined;
      documentType.isApproved = doc && doc.status === DocumentStatus.Approved;
    });
    return documentTypes;
  }

}
