import { Component, OnInit } from '@angular/core';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { Constants } from '../../constants/constants';
import { PermissionsService } from '@app/services/permissions.service';
import { ssrsReportIds, ReportName } from '../../enum/report-name.enum';

export const getReportingState = createFeatureSelector<ReportingState>(
  'reporting'
);

export const selectReportOptions = createSelector(
  getReportingState,
  (state: ReportingState) => state.reportOptions
);

export interface ReportingState {
  reportOptions: [];
  selectedReport: '';
  reportsVisible: boolean;
  reportParameters: '';
  alertMessage: '';
}
@Component({
  selector: 'app-merchandising',
  templateUrl: './merchandising.component.html',
  styleUrls: ['./merchandising.component.scss']
})

export class MerchandisingComponent implements OnInit {
  reportsVisible: boolean = false;
  defaultItem = 'Select a Report'
  public reportOptions: Array<string> = [];

  reportUrl: any;

  constructor(
    private _sanitizer: DomSanitizer,
    private permissions: PermissionsService,
  ) { }

  ngOnInit(): void {
    // adding dropdowns based on permissions
    //Spins Data
    if (this.permissions.userHasAction(Constants.UserActions.SpinsDataRead)) {
      this.reportOptions.push(ReportName.SpinsDataByCategory)
    }
    //Category Data
    if (this.permissions.userHasAction(Constants.UserActions.CategoryDataRead)) {
      this.reportOptions.push(ReportName.CategoryLevelSkuProdByPeriod)
      this.reportOptions.push(ReportName.GBBSalesandVendor)
      this.reportOptions.push(ReportName.SKUOptESNReview)
      this.reportOptions.push(ReportName.GBBDCItemLevelSalesRanking)
      this.reportOptions.push(ReportName.activeRankingYOYEnterprise)
      this.reportOptions.push(ReportName.activeRankingYOYdc)
      this.reportOptions.push(ReportName.GBBEnterpriseLevelScoreandTier)
      this.reportOptions.push(ReportName.DcGBB)

    }
    //Retailer Data
    if (this.permissions.userHasAction(Constants.UserActions.RetailerDataRead)) {
      this.reportOptions.push(ReportName.supplierSolutionSales)
    }
    this.reportOptions.sort();
  }
  reportSelectionChanged(e: any) {
    let reportData
    switch (e) {
      case ReportName.SpinsDataByCategory:
        reportData = ssrsReportIds.get(ReportName.SpinsDataByCategory);
        break;
      case ReportName.CategoryLevelSkuProdByPeriod:
        reportData = ssrsReportIds.get(ReportName.CategoryLevelSkuProdByPeriod);
        break;
      case ReportName.supplierSolutionSales:
        reportData = ssrsReportIds.get(ReportName.supplierSolutionSales);
        break;
      case ReportName.GBBSalesandVendor:
        reportData = ssrsReportIds.get(ReportName.GBBSalesandVendor);
        break;
      case ReportName.SKUOptESNReview:
        reportData = ssrsReportIds.get(ReportName.SKUOptESNReview);
        break;
      case ReportName.activeRankingYOYEnterprise:
        reportData = ssrsReportIds.get(ReportName.activeRankingYOYEnterprise);
        break;
      case ReportName.GBBDCItemLevelSalesRanking:
        reportData = ssrsReportIds.get(ReportName.GBBDCItemLevelSalesRanking);
        break;
      case ReportName.activeRankingYOYdc:
        reportData = ssrsReportIds.get(ReportName.activeRankingYOYdc);
        break;
      case ReportName.GBBEnterpriseLevelScoreandTier:
        reportData = ssrsReportIds.get(ReportName.GBBEnterpriseLevelScoreandTier);
        break;
      case ReportName.DcGBB:
          reportData = ssrsReportIds.get(ReportName.DcGBB);
          break;
      case ReportName.SelectReport:
        this.reportsVisible = false;
        break;
      default:
    }
    this.reportUrl = this._sanitizer.bypassSecurityTrustResourceUrl(`${environment.ssrsReportUrlPrefix}/${reportData.get(environment.current)}?v2=true`);
    this.reportsVisible = true;
  }

}
