import { Component, Input, OnInit } from '@angular/core';
import { IFileUpload } from '@kehe/phoenix-upload';
import { FileRestrictions } from '@progress/kendo-angular-upload';
import { Constants } from '../../../../constants/constants';
import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Destroyable } from '../../../../abstract/destroyable';
import { combineLatest } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { AlertTypes } from '../../../../models/alert.interface';
import { DocumentType } from '../../models/document-type';
import { DocumentUploadRequest } from '../../models/document-upload-request';
import {
  selectDocuments,
  selectDocumentTypes,
  selectIsDocumentUploading,
} from '../../store/supplier-documents.selectors';
import {
  cancelUploadDocumentsModal,
  resetUploadDocumentsModal,
  uploadAndSaveDocument,
} from '../../store/supplier-documents.actions';
import { groupBy, GroupResult } from '@progress/kendo-data-query';
import { selectSupplierLocationContacts } from '../../../supplier-locations/store/supplier-locations.selectors';
import { ContactType } from '../../../../models/contact';
import { formatRemitAddress } from '../../utils/address-format-utils';
import { selectIsFeatureFlagOn } from "@app/shared/state/feature-flag/feature-flag.selectors";
import { FeatureFlags } from "@app/feature-flag";

@Component({
  selector: 'app-upload-documents-modal',
  templateUrl: './upload-documents-modal.component.html',
  styleUrls: [],
})
export class UploadDocumentsModalComponent
  extends Destroyable
  implements OnInit
{
  @Input()
  excludedDocumentTypes = [
    Constants.PurchasingInfoDocumentTypeId,
    Constants.SupplierInfoDocumentTypeId,
    Constants.RetailerRequestedDetailsDocumentTypeId,
  ];

  @Input()
  includedDocumentTypes = [];

  blankInvoiceAlertMessage =
    'The address on the blank invoice must match the remittance address.';
  remitAddressRequiredMessage =
    'Please enter the Remittance Address on the Locations tab.' +
    ' The Remittance Address must match the address on the Blank Invoice.';
  sortedDocumentTypes: DocumentType[] = [];
  fileUploads = [];
  alertTypes = AlertTypes;
  allowMultipleSelect = false;
  isDocumentUploading: boolean;
  ungroupedData: DocumentType[] = [];
  groupedData: GroupResult[] | DocumentType[] = [];
  purchasingInfoToSupplierInfoFF = false;

  @Input()
  filterDocumentTypeId?: number;

  get alertMessage(): string {
    switch (this.documentTypeId) {
      case Constants.PurchasingInfoDocumentTypeId: {
        if (this.purchasingInfoToSupplierInfoFF) {
          return "Once the Legal & Signature tab has been completed, you can schedule a meeting with a member of " +
                 "KeHE's Supplier Optimization team to set up the optimal freight method.";
        } else {
          return "Once the contract documents have been signed in DocuSign, a member of KeHE's onboarding team will" +
                 " reach out to you to review all your options";
        }
      }
      case Constants.SupplierInfoDocumentTypeId: {
        if (this.purchasingInfoToSupplierInfoFF) {
          return "The completed Supplier Information Form should not be uploaded until you have met with " +
                 "KeHE's Supplier Optimization team.";
        } else {
          return 'The Supplier Information Form should not be uploaded until you have met with a member of the ' +
                 'onboarding team.';
        }
      }
      default:
        return null;
    }
  }

  form: UntypedFormGroup;

  get documentTypeIdField() {
    return this.form.get('documentTypeId');
  }

  get documentTypeId(): number {
    return this.documentTypeIdField.value;
  }

  get showStickyHeader() {
    return this.ungroupedData.length > 5;
  }

  get expiresField() {
    return this.form.get('expires');
  }

  get isUploadEnabled() {
    return (
      this.fileUploads.length > 0 &&
      this.form.valid &&
      !this.isDocumentUploading &&
      !this.fileUploads.some((item) => item.validationErrors)
    );
  }

  get expirationDateVisible() {
    return !this.expiresField.disabled;
  }

  get title() {
    return this.filterDocumentType
      ? `Upload ${this.filterDocumentType.name}`
      : 'Upload File(s)';
  }

  get message() {
    if (this.purchasingInfoToSupplierInfoFF) {
      switch (this.documentTypeId) {
        case Constants.PurchasingInfoDocumentTypeId: {
          return `Upload your partially completed Supplier Information Form below.`;
        }
        case Constants.SupplierInfoDocumentTypeId: {
          return `Upload your completed Supplier Information Form below.`;
        }
        default:
          break;
      }
    }
    if (this.filterDocumentType) {
      return `Select the file for upload below to attach your ${this.filterDocumentType.name}.`;
    }
    return 'Select document type and file for upload below to attach your files.';
  }

  get hint() {
    switch (this.documentTypeId) {
      case Constants.W9DocumentTypeId: {
        return 'File must be .jpg .png or .pdf';
      }
      case Constants.PurchasingInfoDocumentTypeId:
      case Constants.RetailerRequestedDetailsDocumentTypeId:
      case Constants.SupplierInfoDocumentTypeId: {
        return 'File must be .xls, .xlsm, or .xlsx';
      }
      default:
        return 'File must be .csv, .doc, .jpg, .pdf, .pptx, .xls, .xlsb, .xlsm, .xlsx';
    }
  }

  get uploadRestrictions(): FileRestrictions {
    return {
      allowedExtensions: this.allowedExtensions(),
    };
  }

  get filterDocumentType() {
    return this.filterDocumentTypeId
      ? this.sortedDocumentTypes.find(
          (item) => item.id === this.filterDocumentTypeId
        )
      : null;
  }

  get isBlankInvoice() {
    return this.documentTypeId === Constants.BlankInvoiceDocumentTypeId;
  }

  get remittanceAddressField() {
    return this.form?.get('remittanceAddress');
  }

  get remittanceAddress() {
    return this.remittanceAddressField?.value;
  }

  constructor(private _store: Store, private _formBuilder: UntypedFormBuilder) {
    super();
    this._store
        .select(
          selectIsFeatureFlagOn(FeatureFlags.PurchasingInfoToSupplierInfo.key)
        )
        .pipe(takeUntil(this.destroy$))
        .subscribe((value) => {
          this.purchasingInfoToSupplierInfoFF = value;
        });
  }

  allowedExtensions() {
    switch (this.documentTypeId) {
      case Constants.W9DocumentTypeId: {
        return ['.jpg', '.png', '.pdf'];
      }
      case Constants.PurchasingInfoDocumentTypeId:
      case Constants.RetailerRequestedDetailsDocumentTypeId:
      case Constants.SupplierInfoDocumentTypeId: {
        return ['.xls', '.xlsm', '.xlsx'];
      }
      default:
        return [];
    }
  }

  ngOnInit() {
    this._store.dispatch(resetUploadDocumentsModal());
    combineLatest([
      this._store.pipe(select(selectDocumentTypes)),
      this._store.pipe(select(selectDocuments)),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([documentTypes, documents]) => {
        this.updateDocumentTypes(documentTypes, documents);
      });
    this._store
      .pipe(takeUntil(this.destroy$), select(selectIsDocumentUploading))
      .subscribe((val) => {
        this.isDocumentUploading = val;
      });

    this.buildForm();
    this._store
      .select(selectSupplierLocationContacts)
      .pipe(takeUntil(this.destroy$))
      .subscribe((contacts) => {
        const address = contacts.find(
          (c) => c.contactType === ContactType.RemittanceAddress
        );
        if (!this.form || !address) {
          return;
        }
        this.remittanceAddressField.setValue(formatRemitAddress(address));
      });
  }

  updateDocumentTypes(
    documentTypes: DocumentType[],
    documents: import('../../models/document').Document[]
  ) {
    const uploadedDocumentTypes = documents
      .map((item) => item.typeId)
      .filter((item) => item !== Constants.OtherTypeId);
    let types = documentTypes.filter(
      (item) =>
        !item.isDocuSign &&
        (item.allowMultiple || !uploadedDocumentTypes.includes(item.id)) &&
        item.id !== Constants.LetterOfFinancialResponsibilityTypeId &&
        item.id !== Constants.W9DocumentTypeId
    );
    if (!this.filterDocumentTypeId) {
      if (this.includedDocumentTypes.length > 0) {
        types = types.filter((item) =>
          this.includedDocumentTypes.includes(item.id)
        );
      } else {
        types = types.filter(
          (item) => !this.excludedDocumentTypes.includes(item.id)
        );
      }
    }
    this.sortedDocumentTypes = this.markPurchasingInfoDocAsRequired(types).sort(
      (a, b) => a.name.localeCompare(b.name)
    );

    this.ungroupedData = this.sortedDocumentTypes
      .sort((a, b) => Number(b.required) - Number(a.required))
      .map((item) => {
        return {
          ...item,
          group: item.required ? 'Required Documents' : 'Optional Documents',
        };
      });
    this.groupedData = groupBy(this.ungroupedData, [{ field: 'group' }]);
  }

  private markPurchasingInfoDocAsRequired(types: DocumentType[]) {
    return types.map((item) =>
      item.id === Constants.PurchasingInfoDocumentTypeId
        ? { ...item, required: true }
        : item
    );
  }

  private buildForm() {
    this.form = this._formBuilder.group({
      documentTypeId: [this.filterDocumentTypeId, [Validators.required]],
      remittanceAddress: [
        '',
        [
          (control: UntypedFormControl) => {
            if (this.form && this.isBlankInvoice) {
              return Validators.required(control);
            }
            return null;
          },
        ],
      ],
      expires: ['', [Validators.required]],
    });
    this.documentTypeIdField.valueChanges.subscribe((value) => {
      this.expiresField.reset();
      this.updateExpirationDateField(value);

      this.remittanceAddressField.reset(this.remittanceAddress);
      this.remittanceAddressField.markAsTouched();
    });
    this.updateExpirationDateField(this.documentTypeId);
  }

  private updateExpirationDateField(documentTypeID: number) {
    if (documentTypeID && documentTypeID > 0) {
      const selectedDocumentType = this.sortedDocumentTypes.find(
        (item) => item.id === documentTypeID
      );
      if (selectedDocumentType && selectedDocumentType.expiredDateRequired) {
        this.expiresField.enable();
        return;
      }
    }
    this.expiresField.disable();
  }

  public disabledDates = (date: Date): boolean => {
    return date.valueOf() <= Date.now();
  };

  handleKeHEModalEvents() {
    this.clearAndCancel();
  }

  onCancelClicked() {
    this.clearAndCancel();
  }

  clearAndCancel() {
    if (this.isDocumentUploading) {
      return;
    }
    this.form.reset();
    this.fileUploads = [];
    this._store.dispatch(cancelUploadDocumentsModal());
  }

  handleAddFiles(files: IFileUpload[]): void {
    if (this.isDocumentUploading) {
      return;
    }
    if (!this.allowMultipleSelect) {
      this.fileUploads = [];
    }
    files.forEach((file) => {
      this.fileUploads = [
        {
          ...file,
          progress: 100,
          uploading: false,
          uploadComplete: true,
        },
        ...this.fileUploads,
      ];
    });
  }

  handleRemoveFiles(file: IFileUpload): void {
    if (this.isDocumentUploading) {
      return;
    }
    this.fileUploads = [...this.fileUploads].filter((f) => f.uid !== file.uid);
  }

  onUploadClicked() {
    if (
      this.isDocumentUploading ||
      this.form.invalid ||
      this.fileUploads.length === 0 ||
      !this.isUploadEnabled
    ) {
      return;
    }
    const request: DocumentUploadRequest = {
      ...this.form.value,
      file: this.fileUploads[0],
    };
    this._store.dispatch(
      uploadAndSaveDocument({ uploadDocumentRequest: request })
    );
  }

  onFocus(element: any): void {
    element.toggle(true);
  }

  onFilterChange(value: string) {
    if (value) {
      this.groupedData = groupBy(
        this.ungroupedData.filter((t) =>
          t.name.toLocaleLowerCase().includes(value.toLocaleLowerCase().trim())
        ),
        [{ field: 'group' }]
      );
    } else {
      this.groupedData = groupBy(this.ungroupedData, [{ field: 'group' }]);
    }
  }
}
